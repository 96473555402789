<template>
  <section id="container" class="efs_cont">
    <div class="title_wrap">
      <h1>마이페이지</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <!-- <button class="mid_btn fr"> 수정 </button> -->
        <h2>기본 정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>회사명</label>
            <ul>
              <li> {{ userInfo.cmpyNm }}</li>
            </ul>
          </dd>
          <dd>
            <label>성명</label>
            <ul>
              <li> {{ userInfo.userNm }}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>휴대폰번호</label>
            <ul>
              <li>
                {{ userInfo.spTelNo }}
              </li>
            </ul>
          </dd>
          <dd>
            <label>사용자 ID</label>
            <ul>
              <li>{{ userInfo.userId }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>개인 권한 정보</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>관리자 여부</label>
            <ul>
              <li>{{ userInfo.adminYn }}</li>
            </ul>
          </dd>

        </li>
        <li>
          <dd>
            <label>이용약관 및 <br>개인정보 활용 동의 일시</label>
            <ul>
              <li>{{ userInfo.psiAgrDt ? 'Y' : 'N' }} <span class="mgl10">{{ userInfo.psiAgrDt }}</span>
              </li>
            </ul>
          </dd>
          <dd>
            <label>마케팅 동의 일시</label>
            <ul>
              <li>
                <kendo-switch ref="mktAgrRef"
                              v-model="!!userInfo.mktAgrDt"
                              :disabled="true">
                </kendo-switch>
                <span class="mgl10">{{ userInfo.mktAgrDt }}</span>
              </li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button @click="$router.push({name : 'MyPageEdit'})" class="large_btn orange">수정</button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'

export default {
  name: 'MyPageDetail',
  created () {
    const vm = this
    vm.getUserInfo()
  },
  methods : {
    getUserInfo : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/user/info`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.userInfo = response.data.resultData
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
  data () {
    return {
      userInfo : {
        mktAgrDt : null,
      },
      spTelNo : '',
      userPwd : '',
      mktEmailAgrDt : null,
      mktSmsAgrDt : null,
    }
  },
}
</script>

<style scoped>

</style>
